import React from "react"
import { graphql } from 'gatsby';
import { css, Global } from "@emotion/react"
import Layout from "~components/Layout"
import ProductCatalogue from "~components/ProductCatalogue"
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import MakerSpotlight from '~components/MakerSpotlight'
import CrinkleButton from "~components/CrinkleButton"

const Shop = ( data ) => {
  const variants = data.data.sanityShopPage._rawContent.variants
  const content = data.data.sanityShopPage._rawContent
  const { mobile } = breakpoints
  return (
    <>
      <Seo
        title={data.data.sanityShopPage.title}
        image={content.seo?.socialImage?.asset?.url}
        description={content.seo?.metaDescription}
      /> 
      <Global styles={css`
        html{
          background-color: var(--white);
        }
      `}/>
      <ProductCatalogue 
        heading={"All Products"} 
        products={variants}
        css={css`
          background-color: #fff;
          margin-bottom: 5rem;
          ${mobile}{
            margin-bottom: 3rem;
          }
      `}/> 
      <MakerSpotlight />
      <CrinkleButton to="/makers" css={css`background: var(--beige);`}>
        All makers
      </CrinkleButton>
    </>
  )
}

export const query = graphql`
query {
  sanityShopPage {
    title
    _rawContent(resolveReferences: {maxDepth: 10})
  }
}

`

export default Shop