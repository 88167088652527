import React, { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import { Darr } from '~components/Svg'

const Select = React.forwardRef(({ onChange, initialValue, className, children }, ref) => {

  const { mobile } = breakpoints

  // const selectRef = useRef()

  // useLayoutEffect(() => {
  //   if(initialValue){
  //     selectRef.current.value = initialValue
  //   }
  // }, [initialValue])

  return (
    <div 
      className={className}
      css={css`
        position: relative;
        display: inline-block;
    `}>
      <select
          onChange={e => onChange(e)}
          className='h4'
          ref={ref}
          css={css`
              border: 1px solid #000;
              border-radius: 100px;
              /* padding: 1.2rem; */
              -moz-appearance:none; /* Firefox */
              -webkit-appearance:none; /* Safari and Chrome */
              appearance:none;
              width: 100%;
              padding: 11px 40px 11px 20px;
              &:focus{
                cursor: pointer;
              }
              ${mobile} {
                padding: 12px 40px 12px 20px;
								}
          `}
      >
          {children}
      </select>
      <Darr css={css`
        width: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        pointer-events: none;
        ${mobile} {
          width: 10px;
        }
      `}/>
    </div>
  )
})

Select.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
}

Select.defaultProps = {
  children:
  <>
  <option value="one">One</option>
  <option value="two">Two</option>
  <option value="three">Three</option>
  </>,
  onChange: e => null
}

export default Select
