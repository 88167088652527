import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import ProductGrid from '../ProductGrid';
import { Section, breakpoints } from '~styles/global'
import Select from '~components/Select'

const ProductCatalogue = ({ heading, products, className}) => {

  const { mobile } = breakpoints
  const [selected, setSelected] = useState(null);

  return (
    <div className={className}>
        <Section css={css`
            padding: 8rem 0;
            ${mobile} {
                padding: 6rem 0;
            }
        `}>
            <h1 className="serif"
                css={css`
                justify-self: center;
                align-self: center;
                grid-column-start: 3;
                grid-column-end: 11;
                ${mobile} {
                    grid-column-start: 1;
                    grid-column-end: 13;
                    margin-bottom: 3rem;
                }
            `}>
                {heading}
            </h1>
            {/* <div css={css`
                justify-self: right;
                align-self: center;
                grid-column-start: 11;
                grid-column-end: 13;
                ${mobile} {
                    justify-self: center;
                    grid-column-start: 1;
                    grid-column-end: 13;
                }
            `}>
                <Select onChange={e => setSelected(e.target.value)}>
                  <option value="one">Sort</option>
                  <option value="two">Recently added</option>
                  <option value="three">Three</option>
                </Select>
            </div> */}
        </Section>
        <ProductGrid sort={selected} products={products}/>
    </div>
  )
}

ProductCatalogue.propTypes = {
    heading: PropTypes.string,
}

ProductCatalogue.defaultProps = {
    heading: 'All products'
}

export default ProductCatalogue
